import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import contacts from "../lib/contacts";
import "./helpPhone.scss";
import PhoneLink from "./PhoneLink";

function HelpPhone() {
  return (
    <section id="section-help-phone" className="section-help-phone">
      <Container>
        <Row>
          <div className="wrapper">
            <p>Остались вопросы? <small>Позвоните нам</small></p>
            <PhoneLink className="btn btn-primary btn-lg" phone={contacts.phones[0]}/>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default HelpPhone;
