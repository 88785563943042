import * as React from "react";
import Container from "react-bootstrap/Container";
import PriceItem from "./PriceItem";
import price from "../../../lib/price";
import "./price.scss";


function IndexPrice() {
  return (
    <section id="section-price" className="section-price">
      <Container>
        <PriceItem price={price.car.price} title={price.car.title} priceKey="car"/>
        <PriceItem price={price.van.price} title={price.van.title} priceKey="van"/>
        <PriceItem price={price.tir.price} title={price.tir.title} priceKey="tir"/>
      </Container>
    </ section>
  )
}

export default IndexPrice;