import * as React from "react";
import {withPrefix} from "gatsby";
import {Helmet} from 'react-helmet';
import Footer from "../components/Footer";
import MainBar from "../components/MainBar";
import OgImage from "../images/ogimage.jpg";
import "../index.scss";
import {setUTM} from "../lib/utm";


function withLayout(WrappedComponent, metaTitle, metaDescription) {
  function Layout(props) {
    const url = `${props.location.protocol}//${props.location.host}${props.location.pathname}`;

    React.useEffect(() => {
      setUTM(props.location);
      let interval = setInterval(() => {
        if (window.Modernizr) {
          clearInterval(interval);
          window.Modernizr.on('webp', function(result) {
            if (!result) {
              window.document.documentElement.classList.add("no-webp");
            }
          });
        }
      }, 100);
    }, [props.location]);

    return (
      <>
        <Helmet htmlAttributes={{
          lang: "ru",
        }}>
          <link rel="canonical" href={url}/>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription}/>
          <meta property="og:url" content={url}/>
          <meta property="og:title" content={metaTitle}/>
          <meta property="og:description" content={metaDescription}/>
          <meta property="og:image" content={OgImage}/>

          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:title" content={metaTitle}/>
          <meta name="twitter:description" content={metaDescription}/>
          <meta name="twitter:image" content={OgImage}/>
          <meta name="yandex-verification" content="f44f7894a0c31837" />
          <script type="text/javascript" src={withPrefix("modernizr-custom.js")}/>
        </Helmet>

        <MainBar/>
        <WrappedComponent {...props}/>
        <Footer/>
      </>
    )
  }

  return Layout;
}

export default withLayout;
