import * as React from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import OnlineForm from "../../Online";
import {trackEvent} from "../../../lib/track";
import price from "../../../lib/price";


function PriceModal({title, transport}) {
  const [show, setShow] = React.useState(false);

  const showModal = () => {
    trackEvent("Modal", "Show", title, true);
    setShow(true);
  }

  const hideModal = () => {
    setShow(false);
  }

  return (
    <>
      <Button size="sm" onClick={showModal}>Записаться</Button>
      <Modal show={show} onHide={hideModal} className={"modal-online"}>
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>
          <OnlineForm
            title={null}
            subject="Записаться онлайн"
            selectTransport={transport}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

PriceModal.propTypes = {
  title: PropTypes.node.isRequired,
  transport: PropTypes.oneOf(Object.keys(price)).isRequired
}

export default PriceModal;