import * as React from "react";
import {Link} from 'gatsby';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Telephone from "bootstrap-icons/icons/telephone.svg";
import GeoAlt from "bootstrap-icons/icons/geo-alt.svg";
import Clock from "bootstrap-icons/icons/clock.svg";
import contacts from "../lib/contacts";
import ModalCallback from "./ModalCallback";

import LogoSvg from "../images/logo.svg";
import FlagRu from "../images/flag-russia.png";
import FlagBy from "../images/flag-belarus.png";
import FlagKz from "../images/flag-kazahstan.png";
import FlagPl from "../images/flag-poland.png";
import PhoneLink from "./PhoneLink";
import {trackEventWrap} from "../lib/track";

const MainBar = () => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Navbar bg="light" expand="lg" fixed="top" expanded={expanded}>
      <Container fluid={"lg"}>
        <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
          <img src={LogoSvg} alt="Логотип TotalDPF Россия"/>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)}>
          <Clock/>
          <GeoAlt/>
          <Telephone/>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav" onClick={() => setExpanded(false)}>
          <div className="contacts">
            <div className="contacts_col services">
              <strong>Гарантийные сервисы</strong>
              <ul>
                <li><img src={FlagRu} alt=""/></li>
                <li><img src={FlagBy} alt=""/></li>
                <li><img src={FlagKz} alt=""/></li>
                <li><img src={FlagPl} alt=""/></li>
              </ul>
            </div>

            <div className="contacts_col workHours">
              <strong>{contacts.workHours[0]}</strong>
              {contacts.workHours[1]}
            </div>

            <div className="contacts_col address">
              <strong>{contacts.address.city}, {contacts.address.street}</strong>
              <Link to="#section-map" onClick={() => trackEventWrap("Map", "Click", "", true)}>Посмотреть на карте</Link>
            </div>

            <div className="contacts_col phone">
              <PhoneLink phone={contacts.phones[0]}/>
              <ModalCallback btnChild="Заказать звонок" form={{title: "Заказ звонка"}}/>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default MainBar;
