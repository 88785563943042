import * as React from "react";
import PropTypes from "prop-types";
import PriceModal from "./PriceModal";
import price from "../../../lib/price";

function PriceItem({title, price, priceKey}) {
  return (
    <div className="price_item">
      <span className="label">Чистка сажевого фильтра</span>
      <span className="title">{title}</span>
      <span className="price">{price.toLocaleString("ru")} ₽</span>
      <PriceModal title={title} transport={priceKey}/>
      <span className="price_description">* цена указана за чистку снятого сажевого фильтра и НЕ МЕНЯЕТСЯ от степени его загрязнения</span>
    </div>
  )
}

PriceItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceKey: PropTypes.oneOf(Object.keys(price)).isRequired
}

export default PriceItem;