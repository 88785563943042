/**
 *
 * @param {string} category
 * @param {string} action
 * @param {string} label Ярлык события
 * @param {boolean} nonInteraction Событие без взаимодействия
 */
export function trackEvent(category, action, label, nonInteraction = false) {
  window.dataLayer = window.dataLayer || [];
  let obj = {
    event: 'event',
    category,
    action,
    label,
    nonInteraction
  }
  window.dataLayer.push(obj);
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log('Track Event', obj);
  }
}

/**
 * Обёртка вокруг trackEvent для отложенного выполнения, когда функция передается через props
 *
 * @param {string} category
 * @param {string} action
 * @param {string} label Ярлык события
 * @param {boolean} nonInteraction Событие без взаимодействия
 */
export function trackEventWrap(category, action, label, nonInteraction = false) {
  return () => {
    trackEvent(category, action, label, nonInteraction);
  }
}