import * as React from "react";
import PropTypes from "prop-types";
import {trackEventWrap} from "../lib/track";

function PhoneLink({className, phone}) {
  return (
    <a href={phone.href}
       className={className}
       onClick={trackEventWrap("Phone", "Click", phone.value)}
    >
      {phone.value}
    </a>
  )
}

PhoneLink.propTypes = {
  className: PropTypes.string,
  phone: PropTypes.shape({
    href: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired
}

export default PhoneLink;