import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Clock from "bootstrap-icons/icons/clock.svg";
import Award from "bootstrap-icons/icons/award.svg";
import Droplet from "bootstrap-icons/icons/droplet.svg";
import OnlineForm from "../../Online";
import contacts from "../../../lib/contacts";
import PhoneLink from "../../PhoneLink";

import "./header.scss";

function IndexHeader() {
  return (
    <header id="section-header" className={"section-header"}>
      <Container>
        <Row>
          <h1>Чистка сажевого фильтра в Москве <small>выгоднее замены на новый</small></h1>
          <p className="slogan">Сохранение заводских настроек автомобиля и экологических норм эксплуатации</p>
        </Row>

        <Row>
          <Col xl={8}>
            <ul className="advantages">
              <li>
                <i><Clock/></i>
                <div>
                  <span className="header">В течениие 15 минут</span>
                  <span className="description">
                    Диагностика снятого сажевого фильтра в присутствии клиента<br/>
                    Процесс чистки от 1 до 3 часов
                  </span>
                </div>
              </li>

              <li>
                <i><Award/></i>
                <div>
                  <span className="header">Удаление 99 % загрязнений</span>
                  <span className="description">
                    Возникающих из сажи, серы, масла из двигателя и охлаждающей жидкости,<br/>
                    без повреждения картриджа фильтра
                  </span>
                </div>
              </li>

              <li>
                <i><Droplet/></i>
                <div>
                  <span className="header">Очистка DPF / FAP / SCR / KAT на современном Европейском оборудовании</span>
                  <span className="description">
                    С применением  экологически чистых моющих средств, безопасных для фильтрующего элемента.<br/>
                    Предоставляются результаты чистки с показателями ДО и ПОСЛЕ промывки
                  </span>
                </div>
              </li>
            </ul>
          </Col>

          <Col xl={4}>
            <OnlineForm
              subject="Записаться онлайн"
              title={<>
                <strong>Оставить заявку</strong>
                или записаться по телефону <PhoneLink phone={contacts.phones[0]}/>
              </>}
              alert={<><strong>Предварительная запись обязательна</strong></>}
            />
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default IndexHeader;