export function setUTM(location) {
  if (!location.search) return;
  const q = new URLSearchParams(location.search);
  const r = {
    utm_source: q.get("utm_source") || "",
    utm_medium: q.get("utm_medium") || "",
    utm_campaign: q.get("utm_campaign") || "",
    utm_term: q.get("utm_term") || "",
    utm_content: q.get("utm_content") || ""
  }
  if (r.utm_source && r.utm_medium && r.utm_campaign) {
    localStorage.setItem("utm", JSON.stringify(r));
  }
}

export function getUTM() {
  const utm = localStorage.getItem("utm");
  const res = {
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  };
  if (!utm) {
    return res;
  }
  try {
    const json = JSON.parse(utm);
    res.utm_source = json["utm_source"] || "";
    res.utm_medium = json["utm_medium"] || "";
    res.utm_campaign = json["utm_campaign"] || "";
    res.utm_term = json["utm_term"] || "";
    res.utm_content = json["utm_content"] || "";
    return res;
  } catch (e) {
    return res;
  }
}