import * as React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OnlineForm from "./Online";
import {trackEvent} from "../lib/track";


function ModalCallback({btnChild}) {
  const [show, setShow] = React.useState(false);
  const header = "Заказ звонка";

  const showModal = () => {
    trackEvent("Modal", "Show", header, true);
    setShow(true);
  }

  const hideModal = () => {
    setShow(false);
  }

  return (
    <>
      <Button size="sm" onClick={showModal}>{btnChild}</Button>
      <Modal show={show} onHide={hideModal} className={"modal-online"}>
        <Modal.Header closeButton>{header}</Modal.Header>
        <Modal.Body>
          <OnlineForm title={null} subject="Заказ звонка" noSelectTransport/>
        </Modal.Body>
      </Modal>
    </>
  )
}

ModalCallback.propTypes = {
  btnChild: PropTypes.node.isRequired,
}

export default ModalCallback;