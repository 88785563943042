import * as React from "react"
import withLayout from "../hoc/withLayout";
import IndexHeader from "../components/index/header/Header";
import IndexPrice from "../components/index/price/Price";
import Ecology from "../components/Ecology";
import Info from "../components/index/info/Info";
import About from "../components/About";
import HelpPhone from "../components/HelpPhone";
import Map from "../components/Map";

// markup
const IndexPage = () => {
  return (
    <>
      <IndexHeader/>
      <main>
        <IndexPrice/>
        <Ecology/>
        <Info/>
        <HelpPhone/>
        <About/>
        <Map/>
      </main>
    </>
)
}


export default withLayout(
IndexPage,
"Чистка сажевых фильтров в Москве",
"Удаление 99% загрязнений возникающих из сажи, пепла, масла из двигателя и охлаждающей жидкости, без повреждения картриджа фильтра");
