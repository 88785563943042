const contacts = {
  phones: [
    {
      href: "tel:+79151536063",
      value: "+7 (915) 153-60-63"
    },
  ],
  address: {
    city: "Москва",
    street: "п. Марушкинское, квартал № 50",
    longitude: '',
    latitude: ''
  },
  workHours: [
    "09:00 - 20:00 без выходных",
    "Другое время - по согласованию"
  ]
};

export default contacts;