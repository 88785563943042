import * as React from "react";
import Container from "react-bootstrap/Container";
import "./ecology.scss";

function Ecology() {
  return (
    <section className="section-eco" id="section-eco">
      <Container>
        <div className="eco_card">
          <p>Оборудование без вреда для экологии!</p>
          <p>Моющие средства не загрязняют окружающую среду.</p>
          <p>Процесс очистки происходит в закрытом контуре машины, не выбрасывая вредные вещества в атмосферу.</p>
        </div>
      </Container>
    </section>
  )
}

export default Ecology;