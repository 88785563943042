const price = {
  car: {
    title: "Легковой автомобиль",
    price: 15000
  },
  van: {
    title: "Коммерческий транспорт / микроавтобус / минивен",
    price: 17000
  },
  tir: {
    title: "Грузовой транспорт",
    price: 35000
  }
};

export default price;